* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: "Fira Sans Condensed";
  font-size: 14px;
}

a {
  text-decoration: none;
  color: white;
}

h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ************* APP ******************** */

.App {
  width: 100%;
  margin: auto;
  /* background-color:  rgb(7, 7, 7); */
}

.FalseVideo {
  display: none !important;
}

/* ************* LOGO LOAD ******************** */

.Initial-Logo {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 4;
}

.Section1 .Initial-Logo video {
  width: 250px;
  height: auto;
  opacity: 0;
}

.Section1 .Initial-Logo img {
  width: 250px !important;
  height: auto !important;
  opacity: 0;
  transform: translate(0px, -20px);
  object-fit: contain !important;
  transform: scale(1.2);
  margin-left: 0px !important;
}

.Section1 .Initial-Logo .Initial-Gif {
  width: 300px !important;
}

.LogoImageBG {
  /* background-image: url("../public/img/logo/blackyellowlogo-bg.png"); */
}

/* ************* SECTION 1 ******************** */

.Section1 {
  position: relative;
  min-width: 100%;
  width: 100%;
  min-height: 100vh;
}

.BG-blackblack {
  background-color: black;
}

.BG-purple {
  background: rgb(80, 15, 87);
}

.BG-pink {
  background-color: rgb(212, 140, 156);
}

.BG-blue {
  background: #cb41cf;
  background: linear-gradient(to right, #e9b2ff, #ae2452);
}

.BG-red {
  background: #da4453;
  background: -webkit-linear-gradient(to right, #da4453, #89216b);
  background: linear-gradient(to right, #da4453, #89216b);
}

.BG-blush {
  background-color: #e79b8e;
}

.BG-palepink {
  background-color: #e4c6b4;
}

.BG-gold {
  background-color: rgb(229 172 70);
}

.BG-vibrantblush {
  background-color: #f85c56;
}

.BG-brownblush {
  background-color: #dfab8e;
}

.BG-crimson {
  background-color: #d52707;
}

.BG-paper {
  background-color: #f3eadb;
}

.BG-black {
  background-color: rgb(10 19 26);
}

.BG-softpink {
  background-color: rgb(241 147 130);
}

.BG-softpink2 {
  background-color: rgb(197 148 145);
}

.BG-darkpurple {
  background-color: #130518;
}

.BG-steel {
  background-color: rgb(96 115 111);
}

.BG-sand {
  background-color: rgb(184 137 92);
}

.BG-brightorange {
  background-color: rgb(251 63 1);
}

.BG-sand2 {
  background-color: rgb(209 188 144);
}

.BG-sand3 {
  background-color: rgb(202 184 161);
}

.BG-jeanblue {
  background-color: rgb(70 118 211);
}

.Font-black {
  color: black;
}

.Font-white {
  color: white;
}

.Section1 .Logo {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 300px;
}

.Section1 .Logo .Blurred {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 300px;
  filter: brightness(1000) blur(15px);
  opacity: 0.3;
  z-index: -1;
}

.Section1 .Logo img {
  height: 95px;
}

.Section1 .Social {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  z-index: 2;
}

.Social svg {
  margin-right: 10px;
  cursor: pointer;
}

.Social svg:hover {
  transform: scale(1.1);
}

.Columns {
  display: flex;
  width: 100%;
  max-width: 1280px;
  /* margin: auto; */
  height: 100vh;
  position: relative;
  padding-top: 150px;
}

.Left-Col {
  width: 50%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Left-Col h1 .Underline {
  border-bottom: 3px solid white;
}

.Left-Col h1 {
  font-size: 72px;
  font-weight: 800;
}

.Left-Col h2 {
  font-size: 32px;
  font-weight: 600;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 60px;
}

.Style-fullscreen h2 {
  text-shadow: 0px 0px 11px rgb(0 0 0);
}

.Left-Col h2 strong {
  font-weight: 500;
}

.Left-Col h1 span {
  padding-left: 0px;
}

.Left-Col .Buttons-Bar {
  display: flex;
}
.Left-Col .Buttons-Bar .Button {
  width: 150px;
  height: 40px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
}

.Left-Col .Buttons-Bar .Button:hover {
  transform: scale(1.05) translate(0px, 10px) !important;
  box-shadow: 0px 20px 20px rgb(0 0 0 / 30%);
}

.Stake-Highlight {
  padding: 15px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 26px;
  background-color: rgb(255 232 0);
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 1);
  /* background: linear-gradient(rgb(125 15 137) 0%, rgb(169 36 76) 100%, rgba(58,165,139,1) 69%, rgba(44,101,109,1) 100%); */
  color: rgb(7, 7, 7);
  /* color: white; */
  margin-top: 50px;
  width: 417px;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  border: 1px solid #7b00ff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.404);
  /* box-shadow: 0px 20px 15px rgb(29 0 93 / 21%); */
}

.Stake-Highlight span {
  width: 23px;
  margin-right: 10px;
  margin-left: 4px;
}

.Left-Col .Buttons-Bar .FeatureButton2 {
  background-color: rgb(255, 230, 8);
  color: black;
  display: flex;
}

.Right-Col {
  width: 50%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 250px;
  display: none;
}

.Right-Col .FeatureButton {
  width: 300px;
  height: 60px;
  background-color: rgb(255, 230, 8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 22px;
  font-weight: 600;
  box-shadow: 0px 30px 20px rgb(0 0 0 / 50%);
  user-select: none;
  cursor: pointer;
  border: 2px solid white;
  transition: 0.2s;
}

.Right-Col .FeatureButton:hover {
  transform: scale(1.02) rotate(0deg) !important;
  transition: 0.5s;
  box-shadow: 0px 50px 30px rgb(0 0 0 / 40%);
}

@keyframes bounce {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 15px);
  }
}

.ArrowDown {
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  z-index: 1;
  color: black;
  font-weight: 500;
  box-shadow: 0px 25px 20px rgb(0 0 0 / 52%);
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
  background-color: white;
  bottom: 25px;
  display: none;
}

.ArrowDown:hover {
  font-weight: 600;
  font-size: 24px;
}

.ArtistInfo {
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}

.ArtistInfo a {
  margin-left: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.301);
}

.Font-black .ArtistInfo a {
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.Artwork {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.BG-overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(2 0 36) 0%, rgb(255 224 0 / 8%) 50%);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;
  z-index: 3;
}

.Initial-Overlay {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(162deg, rgba(80,15,87,1) 0%, rgba(186,32,78,1) 30%, rgba(58,165,139,1) 69%, rgba(44,101,109,1) 100%);  */
  background: #2d0060;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.Style-fullscreen .Artwork img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Style-block .Artwork {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Style-block .Artwork img {
  max-width: 600px;
  margin-left: 400px;
}

/* ************* SECTION 2 ******************** */

.Calculator {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InnerCalculator {
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  width: 500px;
}

.Calculator .Calc-Column {
  width: 50%;
  padding: 5px;
}

.Calculator .Title {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.478);
  padding-bottom: 10px;
}

.Calculator .UserAmount {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.Calculator input {
  width: 220px;
  font-size: 36px;
  border: 2px solid white;
  background-color: rgb(255 255 255);
  padding: 10px;
  padding-left: 50px;
  color: #000000;
  letter-spacing: -1.5px;
  font-weight: 800;
  box-shadow: 0px 10px 23px rgb(0 0 0 / 53%);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 10px 25px;
  background-image: url("../public/img/logo/solana-sol-logo.svg");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.Calculator .Info {
  width: 100%;
  margin-top: 20px;
}

.Calculator .Info .Line {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: #ededed;
  opacity: 0.8;
}

.Calculator .Rewards {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  padding-left: 20px;
}

.Calculator .Row {
  padding: 5px;
  font-size: 16px;
}

.Calculator .RowPeriod {
  color: white;
  font-weight: 400;
}

.Calculator span {
  color: yellow;
  font-weight: 800;
  font-size: 25px;
}

.Address {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.096);
  padding-bottom: 10px;
}

.AddressTitle {
  margin-top: 30px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.AddressLine {
  margin-top: 30px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.AddressLine span {
  font-size: 16px;
  color: white;
  margin-left: 10px;
}

.AddressLine span:hover {
  transform: scale(1.3) rotate(5deg);
  cursor: pointer;
}

/* ************* SECTION 2 ******************** */

.Section {
  background-size: cover;
  background-position: 50% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  padding-top: 100px;
  position: relative;
}

.Section2 {
}

.Section .Header {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 50px;
}

.Section .HeaderColumn {
  flex-flow: column;
  max-width: 1000px;
}

.Section .HeaderColumn h1 {
  /* text-align: center; */
}

.Section h1 {
  font-size: 90px;
  font-weight: 800;
  color: white;
}

.Section h2 {
  font-size: 32px;
  font-weight: 400;
  color: white;
  width: 100%;
}

.Section .Header .Column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes bounce2 {
  from {
    transform: translate(0px, 0px) rotate(10deg);
  }
  to {
    transform: translate(0px, 15px) rotate(14deg);
  }
}

.Section .Header .Column .FeatureImage img {
  width: 500px;
  transform: rotate(10deg);
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}

.Section .Tutorial {
  margin-top: 50px;
  width: 100%;
  max-width: 720px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Section .Tutorial .Heading {
  font-size: 24px;
  font-weight: 600;
  color: white;
  padding: 20px;
  width: 500px;
  background-color: rgb(0 0 0 / 78%);
  text-align: center;
}

.Section .Tutorial .Heading span {
  font-size: 12px;
  transform: translate(4px, -3px);
  display: inline-block;
}

.Section .Tutorial .Menu {
  width: 500px;
  height: 40px;
  color: white;
  display: flex;
}

.Section .Tutorial .Menu .Button {
  width: 33.333%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.589);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.Section .Tutorial .Menu .Active {
  background-color: yellow;
  color: black;
  font-weight: 500;
}

.Section .Tutorial .Steps {
  display: flex;
  width: 100%;
  margin-top: 50px;
}

.Phantom,
.Solflare,
.Ledger {
  display: flex;
  flex-flow: row wrap;
}

.Hidden {
  display: none;
}

.Section .Tutorial .Steps .Step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  -webkit-filter: blur(0px);
}

.Section .Tutorial .Steps .Step:hover {
  transform: scale(1.05);
  opacity: 1;
}

.Section .Tutorial .Steps:hover .Step:not(:hover) {
  -webkit-filter: blur(3px);
}

.Section .Tutorial .Steps .Tall {
  width: 33.33%;
}

.Section .Tutorial .Steps .Wide {
  width: 100%;
}

.Section .Tutorial .Steps .Step .Number {
  width: 70px;
  height: 70px;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 30px;
  font-weight: 600;
}

.Section .Tutorial .Steps .Step .Description {
  font-weight: 400;
  font-size: 20px;
  color: white;
  margin-top: 20px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.Section .Tutorial .Steps .Step .Description span {
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  color: rgb(32, 6, 56);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-right: 10px;
  min-width: 26px;
}

.Section .Tutorial .Steps .Step .Image {
  margin-top: 20px;
}

.Section .Tutorial .Steps .Tall img {
  width: 100%;
  max-width: 200px;
}

.Section .Tutorial .Steps .Wide img {
  width: 100%;
  max-width: 700px;
}

.Section .Tutorial .Steps .Title {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin-top: 50px;
}

.Section .Tutorial .Steps .Video {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Section .Tutorial .Steps .Video video {
  max-width: 700px;
  max-height: 500px;
  box-shadow: 18px 33px 35px rgb(0 0 0 / 88%);
  width: 324px;
}

.Section .Tutorial .Steps .Video .LedgerVideo {
  max-width: 100%;
  width: 100%;
  max-height: 600px;
  background: rgb(0, 0, 0);
}

.Section .Quote {
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: auto;
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Section .InvertedComma {
  font-size: 140px;
}

.Section .Text {
  font-size: 32px;
  line-height: 36px;
  padding: 30px;
  text-shadow: 0px 4px 30px rgba(255, 255, 255, 0.37);
}

.Section .Text strong {
  font-weight: 800;
}

.Section .Name {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px;
}

.Section .TopName {
  font-size: 30px;
}

.Section .BottomName {
  font-size: 16px;
}

.ArrowDown2 {
  bottom: -15px;
}

.BGs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.BGOverlay-Black {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.144);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.BGOverlay-Image {
  position: absolute;
  left: -150px;
  top: -150px;
  z-index: 0;
  width: 150%;
  height: 120%;
  z-index: -2;
}

.BGOverlay-Image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 20%;
  filter: blur(10px);
}

/* ************* SECTION 3 ******************** */

.Section3 {
}

.Facts {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.Fact {
  width: 50%;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.Fact .Bubble {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background-color: yellow;
  color: rgb(102 0 225);
  font-weight: 800;
  font-size: 36px;
  border-radius: 20px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.404);
  text-align: center;
  border: 1px solid #7b00ff;
}

.Fact .Description {
  margin-top: 20px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  max-width: 400px;
}

.Team {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.Team .BreakGraphic {
  font-size: 80px;
  color: white;
  margin: auto;
}

.Team .TeamBlock {
  width: 100%;
  display: flex;
  align-items: center;
}

.Team .TeamBlock .Heading {
  width: 250px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Team .TeamBlock .Heading .HeadingBox {
  background-color: rgb(45 5 93);
  color: white;
  font-size: 24px;
  font-weight: 600;
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Team .TeamBlock .Description {
  width: calc(100% - 250px);
  padding: 20px;
  color: white;
  font-size: 18px;
  font-weight: 400;
}

/* ************* SECTION 4 ******************** */

.Section4 {
}

.Section4 h1 {
  font-size: 90px;
}

.Section4 .Header .Column .FeatureImage img {
  /* width: 700px; */
}

.Section .Subheader {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 52px;
  color: white;
  background-color: #36046c;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.FAQ {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.FAQ .Column {
  width: 50%;
  padding: 5px;
}

.FAQ .Category {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: hidden;
  margin-bottom: 10px;
}

.FAQ .Heading {
  width: 100%;
  padding: 10px;
  background-color: #545454;
  font-weight: 600;
  font-size: 22px;
}

.FAQ .Heading span {
  font-size: 12px;
  transform: translate(6px, -1px);
  display: inline-block;
}

.FAQ .Button {
  width: 100%;
  font-weight: 400;
  max-height: 51px;
  border-bottom: 1px solid rgb(255 255 255);
  font-size: 16px;
  transition: max-height 0.5s;
}

.FAQ .OpenButton {
  max-height: 800px;
}

.FAQ .Title {
  background-color: #d3d3d3;
  color: black;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
}

.FAQ .OpenButton .Title {
  color: rgb(0, 0, 0);
  user-select: none;
}

.FAQ .Answer {
  background-color: #e5e5e5;
  color: rgb(0, 0, 0);
  padding: 10px;
  line-height: 22px;
}

.FAQ .OpenButton .Answer {
  height: 100%;
}
.FAQ .OpenButton .Answer a {
  color: rgb(54 4 108);
  font-weight: 500;
}

.FAQ .Category1 .Heading {
  background-color: rgb(54 4 108);
}
.FAQ .Category2 .Heading {
  background-color: rgb(54 4 108);
}
.FAQ .Category3 .Heading {
  background-color: rgb(54 4 108);
}

/* ************* SECTION 5 ******************** */

.FooterOuter {
  background-color: #2d0060;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  background-color: #2d0060;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.Footer .Logo {
}

.Footer .Logo img {
  width: 300px;
}

.Footer .Logo video {
  width: 200px;
}

.Footer .Text {
  margin-top: -22px;
}

.Footer .Text img {
  width: 320px;
}

.Footer .Links {
  display: flex;
}

.Footer .Links .Link {
  margin: 5px;
  color: white;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.Footer .Links .Link:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.658);
  transform: scale(1.07);
}

.Footer .Admin {
  display: flex;
  margin-top: 30px;
}

.Footer .Admin .Link {
  margin: 5px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.Footer .Admin .Link:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.658);
  transform: scale(1.07);
}

.Footer .Social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 0px;
}

.Footer .Social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-top: 5px;
}

.Footer .Social svg {
  margin: 5px;
  color: white;
  font-weight: 600;
  font-size: 17px;
}

.overlay {
  width: calc(100vw - 16px);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 30px;
  padding-top: 90px;
  color: white;
}

.overlay-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.overlay-content::-webkit-scrollbar {
  width: 5px;
}

.overlay-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overlay-content::-webkit-scrollbar-thumb {
  background: #888;
}

.CloseOverlay {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 50px;
  cursor: pointer;
}

.CloseOverlay:hover {
  transform: scale(1.2) rotate(3deg);
  transition: 0.5s;
}

/* ------------------------ */
/* Greensock Initial Setup  */

.Left-Col h1,
.Left-Col h2,
.Logo,
.Social a,
.Buttons-Bar .Button {
  opacity: 0;
  transform: translate(0px, -10px);
}

.ArtworkBG,
.BG-overlay,
.ArtistInfo,
.Section1 .ArrowDown,
.Right-Col .FeatureButton {
  opacity: 0;
}

/* ------------------------ */
/* MOBILE  */
/* ------------------------ */

@media screen and (max-width: 1080px) {
  .Section1 {
    height: auto;
  }

  .Section1 .Columns {
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: auto;
    align-items: flex-start;
    padding-left: 50px;
  }

  .Section1 .Left-Col,
  .Section1 .Right-Col {
    width: 100%;
    margin-top: 0px;
    padding: 0px;
  }

  .Section1 .Left-Col {
    margin-top: 50px;
    max-width: 600px;
  }

  .Section1 .Right-Col {
    display: none;
  }

  .Left-Col .Buttons-Bar .FeatureButton2 {
    display: flex;
  }

  .Section1 .ArrowDown {
    display: none;
  }

  .Section .Header {
    /* padding:  20px; */
  }

  .Section .Column {
    width: 100%;
  }

  .Section .Column .FeatureImage {
    width: 100%;
  }

  .Section .Header .Column .FeatureImage img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
  }

  .Section h1 {
    font-size: 72px;
  }

  .Section h2 {
    font-size: 30px;
  }

  .Section .Quote {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .Section .InvertedComma {
    display: none;
  }

  .Section .Quote .Text {
    width: 100%;
    max-width: 800px;
    padding-bottom: 0px;
  }

  .Section .Quote .Name {
    width: 100%;
    max-width: 800px;
  }

  .Section .Bubble {
    font-size: 32px;
    padding: 20px;
  }
}

@media screen and (max-width: 800px) {
  .Section .Header {
    flex-direction: column;
    padding: 0px;
    text-align: center;
  }

  .Section .Header .Column {
    width: 100%;
  }

  .Section .Header .Column .FeatureImage img {
    width: 400px;
  }

  .Section .Tutorial .Steps .Step .Number {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .Section .Tutorial .Steps .Step .Description {
    font-size: 18px;
    text-align: center;
  }

  .Facts {
    flex-direction: column;
  }

  .Fact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Fact .Bubble {
    font-size: 28px;
    max-width: 100%;
    border-radius: 0px;
    border: 0px;
    padding: 15px;
  }

  .Fact .Description {
    font-size: 18px;
    width: 400px;
    width: 100%;
    max-width: 100%;
    text-align: left;
    margin-top: 30px;
  }

  .Team .TeamBlock .Description {
    width: 100%;
    max-width: 100%;
  }

  .FAQ {
    flex-direction: column;
  }

  .FAQ .Column {
    width: 100%;
  }

  .Team .TeamBlock {
    flex-direction: column;
  }

  .Team .BreakGraphic {
    display: none;
  }

  .Team .TeamBlock .Heading {
    width: 100%;
  }

  .Team .TeamBlock .Heading .HeadingBox {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .Left-Col h1 {
    font-size: 56px;
  }

  .Left-Col h2 {
    font-size: 26px;
  }

  .Section .Tutorial .Heading {
    max-width: 400px;
  }

  .Section .Tutorial .Steps {
    flex-flow: column nowrap;
  }

  .Section .Tutorial .Steps .Step {
    flex-flow: column wrap;
    height: 400px;
    width: 100%;
  }

  .Section .Tutorial .Menu {
    flex-direction: column;
    height: auto;
    align-items: center;
    width: 100%;
  }

  .Section .Tutorial .Menu .Button {
    width: 100%;
    max-width: 400px;
    height: 40px;
  }

  .Section .Tutorial .Steps .Step {
    flex-flow: column nowrap;
    height: auto;
    margin-bottom: 30px;
  }

  .Section .Tutorial .Steps:hover .Step:not(:hover) {
    -webkit-filter: blur(0px);
  }

  .Section .Tutorial .Steps .Tall img {
    width: 100%;
    max-width: 270px;
  }

  .Section .Tutorial .Steps .Step .Number {
    margin-top: 0px;
    width: 70px;
    height: 70px;
    font-size: 30px;
  }

  .Section .Tutorial .Steps .Step .Description {
    width: 100%;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .Section .Tutorial .Steps .Step .Description span {
    font-size: 16px;
  }

  .Section .Tutorial .Steps .Step .Video {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .Style-block .Artwork img {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .Section h1 {
    font-size: 56px;
  }

  .Section h2 {
    font-size: 22px;
  }

  .Stake-Highlight {
    width: 100%;
  }

  .Section1 .Initial-Logo img {
    width: 300px;
  }

  .Section1 .Logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0;
    top: 0;
    padding-top: 30px;
  }

  .Section1 .Logo img {
    width: 300px;
    height: auto;
  }

  .Section1 .Social {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 0;
    top: 0;
    display: none;
  }

  .Section1 .Columns {
    padding-left: 0px;
    padding: 20px;
    padding-bottom: 100px;
  }

  .Section1 .Left-Col {
    margin-top: 0px;
  }

  .Section .Tutorial .Heading {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .Section .Tutorial .Heading span {
    font-size: 16px;
    transform: translate(4px, -3px);
    display: inline-block;
  }

  .Section .Tutorial .Steps .Step .Video {
    margin-top: 0px;
  }

  .InnerCalculator {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .Calculator .Calc-Column {
    width: 100%;
  }

  .Calculator .Rewards {
    padding-left: 0px;
  }

  .Calculator .Row {
    text-align: center;
  }

  .Address {
    flex-direction: column;
  }

  .AddressLine {
    margin-top: 5px;
    font-size: 12px;
    flex-direction: column;
  }

  .AddressLine span {
    margin-top: 10px;
    font-size: 26px;
  }

  .Section .Subheader {
    width: 100%;
    text-align: center;
    margin: 0px;
  }

  .FAQ .Column {
    padding: 0px;
  }
}

@media screen and (max-width: 450px) {
  .Section {
    padding: 10px;
  }

  .Left-Col h1 {
    font-size: 52px;
  }

  .Left-Col h2 {
    font-size: 26px;
  }

  .Section .Header .Column .FeatureImage img {
    width: 100%;
  }

  .Left-Col .Buttons-Bar {
    flex-direction: column;
  }

  .Left-Col .Buttons-Bar .Button {
    margin-top: 1px;
    width: 100%;
  }

  .Section2 .Tutorial .Steps .Step {
    flex-flow: column nowrap;
    height: auto;
  }

  .Section2 .Tutorial .Steps .Step .Number {
    margin-top: 0px;
  }

  .Section2 .Tutorial .Steps .Step .Description {
    width: 100%;
  }

  .Section2 .Tutorial .Steps .Step .Video {
    height: auto;
    width: 100%;
  }

  .Section .Tutorial .Steps .Video video {
    width: 260px;
  }

  .Section2 .Quote .Text {
    padding-left: 0px;
    padding-right: 0px;
  }

  .Section2 .Quote .Name {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 400px) {
  .Section1 .Logo img {
    width: 200px;
  }

  .Section1 .Columns {
    padding-bottom: 150px;
  }
}
